import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { CALCULATOR_UNIT, OMNIBUS_LAST_DAYS } from 'Component/ProductActions/ProductActions.config';
import withPopper from 'Util/Popper/Popper.hoc';
import { formatPrice } from 'Util/Price';

import './Hotspot.style';

/** @namespace Pwa/Component/Hotspot/Component/Hotspot */
export class Hotspot extends PureComponent {
    static toPercent = (value = 0) => `${value}%`;

    state = {
        referenceElement: null,
        hasInitialized: false,
    };

    componentDidMount() {
        this.handleInitialHotspot();
    }

    componentDidUpdate() {
        this.handleInitialHotspot();
    }

    handleInitialHotspot() {
        const { referenceElement, is_default } = this.props;
        const { hasInitialized } = this.state;

        if (hasInitialized) {
            return;
        }

        if (referenceElement && is_default) {
            this.setState({
                referenceElement: false,
                hasInitialized: true,
            });
        }
    }

    handlePopperOpen({ target: referenceElement }) {
        this.setState({
            referenceElement,
        });
    }

    handlePopperClose() {
        this.setState({
            referenceElement: null,
        });
    }

    renderHover() {
        return (
            <div block="Hotspot" elem="Hover">
                <span />
            </div>
        );
    }

    renderSquareMeters() {
        const { product_unit } = this.props;
        const isProductWithCalculator = product_unit === CALCULATOR_UNIT;

        if (!isProductWithCalculator) {
            return null;
        }

        return (
            <span block="Hotspot" elem="Qmeters">
                /m<sup>2</sup>
            </span>
        );
    }

    renderLowestPrice() {
        const {
            lowest_price,
            discount_display_view: { percentage_discount = '' } = {},
            percentageDiscountTreshold,
            regular_price,
            final_price,
        } = this.props;
        const isActiveDiscount = regular_price !== final_price && percentageDiscountTreshold < +percentage_discount;

        if (!lowest_price || lowest_price === '' || !isActiveDiscount) {
            return null;
        }

        return (
            <div block="Hotspot" elem="LowestPrice">
                {__('Lowest price in the last %s days', OMNIBUS_LAST_DAYS)}: <span>{lowest_price}</span>
            </div>
        );
    }

    renderPrice() {
        const {
            discount_display_view: { percentage_discount = '' } = {},
            percentageDiscountTreshold,
            regular_price,
            final_price,
            currency: { current_currency_code = '' } = {},
        } = this.props;
        const isActiveDiscount = regular_price !== final_price && percentageDiscountTreshold < +percentage_discount;

        if (isActiveDiscount) {
            return (
                <div block="Hotspot" elem="PriceWrapper">
                    <div block="ProductPrice" mix={{ block: 'Hotspot', elem: 'Price' }}>
                        <ins block="ProductPrice" elem="Price">
                            <span block="ProductPrice" elem="PriceValue">
                                {formatPrice(final_price, current_currency_code)}
                                {this.renderSquareMeters()}
                            </span>
                        </ins>
                        <del block="ProductPrice" elem="HighPrice">
                            {formatPrice(regular_price, current_currency_code)}
                            {this.renderSquareMeters()}
                        </del>
                    </div>
                </div>
            );
        }

        return (
            <div block="Hotspot" elem="PriceWrapper">
                <div block="ProductPrice" mix={{ block: 'Hotspot', elem: 'Price' }}>
                    <span block="ProductPrice" elem="Price">
                        <span block="ProductPrice" elem="PriceValue">
                            {formatPrice(final_price, current_currency_code)}
                            {this.renderSquareMeters()}
                        </span>
                    </span>
                </div>
            </div>
        );
    }

    renderPopper({ image, title, description, url }) {
        const { portal, setPopperElement, setArrowElement, popper } = this.props;
        const { referenceElement } = this.state;
        const { styles, attributes } = popper ?? {};

        if (!referenceElement) {
            return null;
        }

        return createPortal(
            <div block="Hotspot" elem="Popper" style={styles.popper} ref={setPopperElement} {...attributes.popper}>
                <div block="Hotspot" elem="Content">
                    <Link to={url} block="Hotspot" elem="Link">
                        <div block="Hotspot" elem="Arrow" ref={setArrowElement} style={styles.arrow} />
                        {image ? (
                            <div>
                                <Image src={image} alt={title} />
                            </div>
                        ) : null}
                        {title ? (
                            <div block="Hotspot" elem="Title">
                                {title}
                            </div>
                        ) : null}
                        {description ? (
                            <div block="Hotspot" elem="Description">
                                <Html content={description} />
                            </div>
                        ) : null}
                        {this.renderPrice()}
                        {this.renderLowestPrice()}
                    </Link>
                </div>
            </div>,
            portal || document.body
        );
    }

    render() {
        const {
            is_default,
            position_x,
            position_y,
            small_image: image,
            short_description: description,
            product_name_for_listing: title,
            product_url: url,
            setReferenceElement,
            isMobile,
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <div
                block="Hotspot"
                style={{ top: Hotspot.toPercent(position_y), left: Hotspot.toPercent(position_x) }}
                onMouseOver={!isMobile ? this.handlePopperOpen.bind(this) : null}
                onClick={isMobile ? this.handlePopperOpen.bind(this) : null}
                onMouseLeave={this.handlePopperClose.bind(this)}
                ref={setReferenceElement}
                data-is-default={is_default}
            >
                {this.renderHover()}
                {this.renderPopper({
                    image,
                    title,
                    description,
                    url,
                })}
            </div>
        );
    }
}

export default withPopper(Hotspot, {
    strategy: 'absolute',
    placement: 'top',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 21],
            },
        },
    ],
});
