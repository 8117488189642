import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DeviceType } from 'Type/Device.type';
import { ImageType } from 'Type/ProductList.type';
import { noopFn } from 'Util/Common';

import ImageWithHotspots from './ImageWithHotspots.component';

/** @namespace Pwa/Component/ImageWithHotspots/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    percentageDiscountTreshold: state.ConfigReducer.percentage_discount_threshold,
    currency: state.ConfigReducer.currencyData,
});

/** @namespace Pwa/Component/ImageWithHotspots/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ImageWithHotspots/Container/ImageWithHotspotsContainer */
export class ImageWithHotspotsContainer extends PureComponent {
    static propTypes = {
        device: DeviceType.isRequired,
        image: ImageType.isRequired,
        label: PropTypes.string,
        isScrollable: PropTypes.bool,
        setScrollableRef: PropTypes.func,
    };

    static defaultProps = {
        label: '',
        isScrollable: false,
        setScrollableRef: noopFn,
    };

    state = {
        hasInitialized: false,
        scrollableRef: null,
    };

    containerFunctions = {
        setScrollableRef: this.setScrollableRef.bind(this),
    };

    componentDidMount() {
        this.handleInitialImage();
    }

    componentDidUpdate() {
        this.handleInitialImage();
    }

    handleInitialImage() {
        const { hasInitialized, scrollableRef } = this.state;

        if (hasInitialized) {
            return;
        }

        if (scrollableRef) {
            this.setState(
                {
                    hasInitialized: true,
                },
                () => {
                    scrollableRef.querySelector('[data-is-default]')?.scrollIntoView({
                        inline: 'center',
                        block: 'center',
                    });
                }
            );
        }
    }

    setScrollableRef(scrollableRef) {
        const { setScrollableRef } = this.props;

        this.setState(
            {
                scrollableRef,
            },
            () => (typeof setScrollableRef === 'function' ? setScrollableRef(scrollableRef) : null)
        );
    }

    getScrollableWidth() {
        const { scrollableRef } = this.state;
        return Math.ceil((scrollableRef?.getBoundingClientRect().height || 0) * (16 / 9));
    }

    containerProps() {
        const {
            label,
            image,
            device,
            isActiveImage,
            isScrollable = false,
            portal,
            percentageDiscountTreshold,
            currency,
        } = this.props;

        return {
            label,
            device,
            image,
            isActiveImage,
            isScrollable,
            scrollableWidth: this.getScrollableWidth(),
            portal,
            percentageDiscountTreshold,
            currency,
        };
    }

    render() {
        return <ImageWithHotspots {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageWithHotspotsContainer);
