import React, { PureComponent } from 'react';

import Hotspot from 'Component/Hotspot';
import Image from 'Component/Image';

import './ImageWithHotspots.style';

/** @namespace Pwa/Component/ImageWithHotspots/Component/ImageWithHotspots */
export class ImageWithHotspots extends PureComponent {
    renderHotspot(data) {
        const {
            portal,
            device: { isMobile },
            percentageDiscountTreshold,
            currency,
        } = this.props;

        return (
            <Hotspot
                portal={portal}
                {...data}
                isMobile={isMobile}
                percentageDiscountTreshold={percentageDiscountTreshold}
                currency={currency}
            />
        );
    }

    renderImageWithHotspots() {
        const { image } = this.props;

        if (!image?.hotspots?.length) {
            return null;
        }

        return image?.hotspots?.map(this.renderHotspot.bind(this));
    }

    renderImage() {
        const { isActiveImage = false, image, label } = this.props;

        return (
            <Image
                src={image?.url}
                alt={label || image?.label}
                ratio="16x9"
                mix={{
                    block: 'Image',
                    mods: { isActive: isActiveImage },
                }}
            >
                {this.renderImageWithHotspots()}
            </Image>
        );
    }

    render() {
        const { isScrollable } = this.props;

        return (
            <div block="ImageWithHotspots" mods={{ isScrollable }}>
                {this.renderImage()}
            </div>
        );
    }
}

export default ImageWithHotspots;
