import React from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { fetchQuery } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';
import { checkIfUrlIncludes } from 'Util/Url';

import ProductWithHotspotsWidget from './ProductWithHotspotsWidget.component';

import './ProductWithHotspotsWidget.style';

/** @namespace Pwa/Component/ProductWithHotspotsWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductWithHotspotsWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductWithHotspotsWidget/Container/ProductWithHotspotsWidgetContainer */
export class ProductWithHotspotsWidgetContainer extends DataContainer {
    state = {
        product: null,
        hotspots: [],
    };

    componentDidMount() {
        this.getProductData();
    }

    containerProps() {
        const { title, device } = this.props;
        const { product, hotspots } = this.state;
        const isBlog = checkIfUrlIncludes('blog');

        return { title, product, hotspots, device, isBlog };
    }

    getProductData() {
        const { conditionsEncoded: conditions } = this.props;

        this.fetchData(
            [
                ProductListQuery.getQuery({
                    args: {
                        filter: { conditions, builtProducts: 'eq' },
                    },
                    isPlp: true,
                    isArrangementWidget: true,
                    noAttributes: true,
                }),
            ],
            ({ products } = {}) => {
                this.setState({
                    product: products?.items[0],
                });
                this.getImageHotspots(products?.items[0]?.image?.path);
            }
        );
    }

    async getImageHotspots(imagePath) {
        if (!imagePath) {
            return;
        }

        await fetchQuery(ProductListQuery.getImageHotspots(imagePath)).then(
            /** @namespace Pwa/Component/ProductWithHotspotsWidget/Container/fetchQuery/then */
            (data) => {
                this.setState({ hotspots: data.getHotspotsByImagePath });
            }
        );
    }

    render() {
        return <ProductWithHotspotsWidget {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWithHotspotsWidgetContainer);
