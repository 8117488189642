import React, { PureComponent } from 'react';

import ArrangementProductTags from 'Component/ArrangementProductTags';
import Html from 'Component/Html';
import ImageWithHotspots from 'Component/ImageWithHotspots';
import Link from 'Component/Link';

import { HOTSPOT_VISIBLE_TAGS_AMOUNT } from './ProductWithHotspotsWidget.config';

import './ProductWithHotspotsWidget.style';

/** @namespace Pwa/Component/ProductWithHotspotsWidget/Component/ProductWithHotspotsWidget */
export class ProductWithHotspotsWidget extends PureComponent {
    renderTitle() {
        const { title } = this.props;

        return <h2>{title}</h2>;
    }

    renderImage() {
        const { product, hotspots } = this.props;

        return (
            <div block="ProductWithHotspotsWidget" elem="Image">
                <ImageWithHotspots image={{ ...product?.image, hotspots }} label={product?.name} isScrollable />
            </div>
        );
    }

    renderTags(visible = true) {
        const {
            product: { attributes, categories, tags },
        } = this.props;

        if (!tags?.length || !visible) {
            return null;
        }

        return (
            <ArrangementProductTags
                attributes={attributes}
                block="ProductWithHotspotsWidget"
                categories={categories}
                tags={tags}
                isProductWithHotspotsWidget
                visibleTagsAmount={HOTSPOT_VISIBLE_TAGS_AMOUNT}
            />
        );
    }

    renderProduct() {
        const { device: { isMobile } = {}, product } = this.props;
        const productName = product?.listing_name || product?.name;

        return (
            <>
                <h3 block="ProductWithHotspotsWidget" elem="Name">
                    {productName}
                </h3>
                {!isMobile ? (
                    <p block="ProductWithHotspotsWidget" elem="Description">
                        <Html content={product?.short_description?.html} />
                    </p>
                ) : null}
                <Link
                    to="/lazienka-inspiracje.html"
                    block="ProductWithHotspotsWidget"
                    elem="Button"
                    mix={{ block: 'Button', mods: { isHollow: true } }}
                >
                    {__('Ready designs')}
                </Link>
            </>
        );
    }

    render() {
        const { device: { isMobile, isTablet } = {}, product, isBlog } = this.props;

        if (!product) {
            return null;
        }

        return (
            <div block="ProductWithHotspotsWidget">
                {this.renderTitle()}
                <div block="ProductWithHotspotsWidget" elem="Wrapper">
                    {this.renderTags(isMobile || isTablet || isBlog)}
                    {this.renderImage()}
                    <div block="ProductWithHotspotsWidget" elem="Content">
                        {this.renderTags(!isMobile && !isTablet && !isBlog)}
                        {this.renderProduct()}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductWithHotspotsWidget;
